import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,3];

export const dictionary = {
		"/[[language=language]]/(some-affiliate-pages)/casino-promotions": [23,[5]],
		"/[[language=language]]/(default)/crypto-casino/groups": [~7,[2]],
		"/[[language=language]]/(default)/crypto-casino/provider": [~8,[2]],
		"/[[language=language]]/(default)/crypto-casino/recent": [~9,[2]],
		"/[[language=language]]/(default)/crypto-sportsbook": [~10,[2]],
		"/[[language=language]]/(default)/game/orbital.jacksdice": [~12,[2],[,4]],
		"/[[language=language]]/(default)/game/[alias]": [~11,[2,3]],
		"/[[language=language]]/(default)/guide/deposit": [13,[2]],
		"/[[language=language]]/(default)/guide/withdrawal": [14,[2]],
		"/[[language=language]]/(some-affiliate-pages)/no-deposit-bonus": [24,[5]],
		"/[[language=language]]/(default)/promotions/UFC306": [15,[2]],
		"/[[language=language]]/(default)/promotions/betsoft-weekender": [16,[2]],
		"/[[language=language]]/(default)/promotions/bonus-city": [~17,[2]],
		"/[[language=language]]/(default)/promotions/combo-boost": [18,[2]],
		"/[[language=language]]/(default)/promotions/drops-and-wins": [19,[2]],
		"/[[language=language]]/(default)/promotions/jacks-winter-reels-oldd": [~20,[2]],
		"/[[language=language]]/(default)/promotions/treasure-of-aztecs": [~21,[2]],
		"/[[language=language]]/(default)/promotions/welcome-offers": [22,[2]],
		"/[[language=language]]/(some-affiliate-pages)/welcome-bonus": [25,[5]],
		"/[[language=language]]/[...cms]": [26,[],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';